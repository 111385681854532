import React, { useRef } from "react";
import { infoData } from "../../constant/constant";
import { useIntersectionObserver } from "../../animation/intersectionObserver";

const About = () => {
  const elementsRef = useRef([]);
  useIntersectionObserver(elementsRef);

  return (
    <section id="about" className="p-8 font-suse">
      <div className="flex items-center justify-center  ">
        <h2
          ref={(el) => (elementsRef.current[0] = el)}
          className="flex text-4xl font-extrabold text-white font-poppins pb-2 mb-4 text-center border-b-2 border-customBlue w-full max-w-[1200px]  zoom"
        >
          About <b className=" text-customBlue">‎ Me</b>
        </h2>
      </div>
      <div className="flex items-center justify-center ">
        <p
          ref={(el) => (elementsRef.current[1] = el)}
          className="text-lg text-white leading-relaxed text-justify font-light w-full max-w-[1200px] fade "
        >
          Hello and welcome to my personal website! I'm Adam Adha Bin Kamarul
          Zaman, an{" "}
          <b className="font-bold text-customBlue">Analyst Programmer</b> at{" "}
          <b className="font-bold text-customBlue">Public Bank Berhad</b> with
          over a year of professional experience. I graduated from{" "}
          <b className="font-bold text-customBlue">
            Universiti Kebangsaan Malaysia
          </b>{" "}
          with a Bachelor's Degree in{" "}
          <b className="font-bold text-customBlue">Software Engineering</b>{" "}
          (Information System Development). My professional experience has
          enhanced my skills in mobile and web development with{" "}
          <b className="font-bold text-customBlue">React Native</b>,{" "}
          <b className="font-bold text-customBlue">Java</b>,{" "}
          <b className="font-bold text-customBlue">Swift</b> and{" "}
          <b className="font-bold text-customBlue">ReactJS</b> as well as{" "}
          <b className="font-bold text-customBlue">Figma</b> for creating
          engaging <b className="font-bold text-customBlue">UI/UX</b> designs.
          Additionally, my academic journey has given me a strong foundation in{" "}
          <b className="font-bold text-customBlue">Python</b> for{" "}
          <b className="font-bold text-customBlue">machine learning</b>. I have
          substantial expertise in using{" "}
          <b className="font-bold text-customBlue">Scrapy</b>,{" "}
          <b className="font-bold text-customBlue">Numpy</b>, and{" "}
          <b className="font-bold text-customBlue">Pandas</b> for{" "}
          <b className="font-bold text-customBlue">data analysis</b>, and{" "}
          <b className="font-bold text-customBlue">Power BI</b> for{" "}
          <b className="font-bold text-customBlue">data visualization</b>. I'm
          particularly interested in mobile and web development, data
          engineering, UI/UX design, and software testing. I'm eager to apply my
          knowledge and skills to new challenges and contribute to a dynamic
          team.
        </p>
      </div>

      <div className="flex justify-center items-center">
        <div className="flex flex-wrap gap-8  justify-center p-8 max-w-[1200px]  ">
          {infoData.map((info, index) => (
            <div
              ref={(el) => (elementsRef.current[index + 3] = el)}
              className="bg-gray-800 relative border-2 border-customBlue cursor-pointer overflow-hidden w-[350px] rounded-lg group fade "
              key={index}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-gray-800 text-white flex flex-col items-center justify-center opacity-1 group-hover:opacity-0 transition-opacity duration-300 ease-in-out text-center z-10">
                <h1 className="text-customBlue text-xl font-semibold">
                  {info.label}
                </h1>
                <h1 className="text-xl mt-2.5 text-white text-center">
                  {info.value}
                </h1>
              </div>
              <div className="w-full h-full flex flex-col items-center bg-gradient-to-r from-customBlue to-customBlue2 justify-center p-10 box-border z-10 transition-transform transition-opacity duration-300 ease-in-out">
                <p className="text-xl font-suse my-auto text-white font-semibold">
                  {info.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;
