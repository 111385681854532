import React, { useEffect, useRef, useState } from "react";
import { Blurhash } from "react-blurhash";
import {
  FaDownload,
  FaGithub,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  // FaPlay,
  FaResearchgate,
  FaSearch,
  FaYoutube,
} from "react-icons/fa";
import profilePic from "../../assets/images/profile/profileadam.png";
import Resume from "../../assets/resume.pdf";
// import Video from "../../assets/videos/domain.mp4";
import { useIntersectionObserver } from "../../animation/intersectionObserver";
import "../../App.css";

const IntroSection = () => {
  const [subheadingIndex, setSubheadingIndex] = useState(0);
  // const [showVideo, setShowVideo] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const elementsRef = useRef([]);
  // const handleOverlayClick = () => {
  //   setShowVideo(false);
  // };

  // const handleVideoClick = (e) => {
  //   e.stopPropagation(); // Prevent the click from bubbling up to the overlay
  // };

  const subheadings = [
    "Web Developer",
    "Data Engineer",
    "Mobile Developer",
    "UI/UX Designer",
    "Software Tester",
    "Content Creator",
  ];

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const elementPosition = section.offsetTop; // Get the element's position
      const offset = -50; // Define the offset value
      const offsetPosition = elementPosition + offset; // Adjust position by the offset

      // Scroll to the calculated position
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const [typedText, setTypedText] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [showCursor, setShowCursor] = useState(true); // State to control the blinking cursor

  useEffect(() => {
    const typingSpeed = 100; // Speed of typing in ms per letter
    const subheading = subheadings[subheadingIndex];

    const typewriterInterval = setInterval(() => {
      if (typedText.length < subheading.length) {
        setTypedText((prev) => prev + subheading[typedText.length]);
      } else {
        clearInterval(typewriterInterval);
        setIsTyping(false);
      }
    }, typingSpeed);

    return () => clearInterval(typewriterInterval); // Cleanup on component unmount or when subheading changes
  }, [subheadingIndex, typedText]); // Trigger the effect every time the subheading or typed text changes

  useEffect(() => {
    if (!isTyping) {
      const subheadingInterval = setInterval(() => {
        setSubheadingIndex((prevIndex) =>
          prevIndex === subheadings.length - 1 ? 0 : prevIndex + 1
        );
        setTypedText(""); // Reset typed text for the next subheading
        setIsTyping(true); // Trigger typing for the next subheading
      }, 3000); // Change subheading every 3 seconds (after typing finishes)

      return () => {
        clearInterval(subheadingInterval);
      };
    }
  }, [isTyping]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor((prev) => !prev); // Toggle the cursor visibility
    }, 500); // Blinks every 500ms

    return () => clearInterval(cursorInterval);
  }, []);

  useEffect(() => {
    const img = document.createElement("img");
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = profilePic;
  }, [profilePic]);

  useIntersectionObserver(elementsRef);

  return (
    <section
      id="home"
      className="flex flex-col justify-center items-center md:flex-row p-8 "
    >
      <div className="max-w-xl flex-1 mr-4 mb-4 text-center sm:!text-center md:!text-start font-suse ">
        <h1
          ref={(el) => (elementsRef.current[0] = el)}
          className="text-4xl sm:text-4xl md:text-5xl font-extrabold mb-4 zoom"
        >
          Adam Adha Bin Kamarul Zaman
        </h1>
        <div className="min-h-[35px]">
          <h2
            ref={(el) => (elementsRef.current[1] = el)}
            className="text-2xl font-extrabold  text-customBlue fade"
          >
            {typedText}
            {showCursor && <span className="text-customBlue">|</span>}
          </h2>
        </div>
        <p
          ref={(el) => (elementsRef.current[2] = el)}
          className="my-4 text-lg font-normal font-bold fade"
        >
          Seeking a Full-Time Position in Web/Mobile Development, Data
          Engineering, UI/UX Designing or Software Testing.
        </p>
        <p
          ref={(el) => (elementsRef.current[3] = el)}
          className="my-4 text-lg font-normal fade"
        >
          Available from February 2025 onwards
        </p>
        <div className="flex flex-wrap justify-center  md:!justify-start bg-transparent">
          <a
            onClick={() => scrollToSection("skills")}
            ref={(el) => (elementsRef.current[4] = el)}
            className="text-md bg-customBlue text-black font-bold py-2 px-6 rounded mx-2 my-2 cursor-pointer w-40 text-center fade"
          >
            <div className="flex flex-row items-center justify-center">
              <div className="mr-2">
                <FaSearch className="fa-beat" />
              </div>
              <div>My Skills</div>
            </div>
          </a>
          <a
            href="/Resume Adam Adha Bin Kamarul Zaman - Programmer And Designer.pdf"
            target="_blank"
            rel="noopener noreferrer"
            ref={(el) => (elementsRef.current[5] = el)}
            download="Resume Adam Adha Bin Kamarul Zaman - Programmer And Designer.pdf"
            className="text-md bg-transparent text-customBlue font-bold py-2 px-6 border-1 border-customBlue rounded mx-2 my-2 cursor-pointer w-40 text-center fade"
          >
            <div className="flex flex-row items-center justify-center">
              <div className="mr-2">
                <FaDownload className="fa-beat" />
              </div>
              <div>Resume</div>
            </div>
          </a>
          {/* <a
              onClick={() => setShowVideo(true)}
              ref={(el) => (elementsRef.current[6] = el)}
              className="text-sm text-black font-bold py-2 px-6 rounded mx-2 my-2 cursor-pointer bg-customBlue w-40 text-center flex items-center justify-center fade"
            >
              <div className="flex flex-row items-center ">
                <div className="mr-2">
                  <FaPlay className="fa-beat" />
                </div>
                <div>Teaser Video</div>
              </div>
            </a> */}
        </div>

        <div
          ref={(el) => (elementsRef.current[7] = el)}
          className="flex flex-row items-center mt-8 fade justify-center  md:!justify-start"
        >
          <a
            href="https://www.linkedin.com/in/adamadha3/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-xl mx-2 transition-colors duration-300"
          >
            <FaLinkedin className="text-2xl" />
          </a>
          <a
            href="https://www.researchgate.net/profile/Adam-Adha-2"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-xl mx-2 transition-colors duration-300"
          >
            <FaResearchgate className="text-2xl" />
          </a>
          <a
            className="text-white text-xl mx-2 transition-colors duration-300"
            href="https://github.com/adamadha8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub className="text-2xl" />
          </a>
          <a className="text-gray-400 text-xl mx-2">
            <FaInstagram className="text-gray-400 text-2xl cursor-not-allowed" />
          </a>
          <a className="text-gray-400 text-xl mx-2">
            <FaYoutube className="text-gray-400 text-2xl cursor-not-allowed" />
          </a>
          <a className="text-gray-400 text-xl mx-2">
            <FaTiktok className="text-gray-400 text-xl cursor-not-allowed" />
          </a>
        </div>
      </div>
      <div
        ref={(el) => (elementsRef.current[8] = el)}
        className="flex-1 flex justify-center items-center relative mb-4 mt-10  h-96  sm:h-[380px] sm:mt-10 md:mt-0 max-w-[380px] zoom"
      >
        {!imageLoaded && (
          <div className="w-96 h-96 rounded-full object-cover overflow-hidden">
            <Blurhash
              hash="LdIh+=NG_MVsMxRQixbuIAR+RjRk"
              width="100%" // Make Blurhash fill the container
              height="100%" // Make Blurhash fill the container
              resolutionX={32}
              resolutionY={32}
              punch={1}
            />
          </div>
        )}

        <img
          onLoad={() => setImageLoaded(true)}
          loading="lazy"
          src={profilePic}
          alt="Adam Kz"
          className={`w-200 h-200  rounded-full object-cover overflow-hidden shadow-[0_0_22.5px_2px_rgba(0,171,240,1.0)] ${
            imageLoaded ? "block" : "hidden"
          }`}
        />
      </div>
    </section>
    //  {showVideo && (
    //   <div
    //     className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-80 flex justify-center items-center z-50"
    //     onClick={handleOverlayClick}
    //     role="dialog"
    //     aria-labelledby="videoModalTitle"
    //     aria-modal="true"
    //   >
    //     <div onClick={handleVideoClick} className="relative">
    //       <video
    //         controls
    //         className="max-h-[600px] w-auto"
    //         aria-labelledby="videoModalTitle"
    //       >
    //         <source src={Video} type="video/mp4" />
    //         Your browser does not support the video tag.
    //       </video>
    //       <button
    //         onClick={() => setShowVideo(false)}
    //         className="absolute top-4 right-4 text-white text-4xl bg-transparent border-none cursor-pointer"
    //         aria-label="Close"
    //         style={{ zIndex: 51 }} // Ensure button is above other elements if needed
    //       >
    //         &times;
    //       </button>
    //     </div>
    //   </div>
    // )}
  );
};

export default IntroSection;
