import React, { useEffect, useRef, useState } from "react";
import { Blurhash } from "react-blurhash"; // Import Blurhash component
import { portfolioData } from "../../constant/constant";
import { useIntersectionObserver } from "../../animation/intersectionObserver";
import { ModalView } from "../modal/ModalView";

const Portfolio = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [imageLoaded, setImageLoaded] = useState({});
  const [activeFilter, setActiveFilter] = useState("Mobile");

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = "hidden"; // Disable scroll
    } else {
      document.body.style.overflow = "auto"; // Enable scroll
    }
  }, [modalIsOpen]);

  const openModal = (item) => {
    setSelectedItem(item);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedItem(null);
  };

  const cardsRef = useRef([]);
  useIntersectionObserver(cardsRef, activeFilter); // Pass activeFilter here

  useEffect(() => {
    portfolioData.forEach((item, index) => {
      const img = new Image();
      img.src = item.image;
      img.onload = () => {
        setImageLoaded((prevState) => ({ ...prevState, [index]: true }));
      };
    });
  }, []);

  const filteredData =
    activeFilter === "All"
      ? portfolioData
      : portfolioData.filter((item) => item.category.includes(activeFilter));

  const filterOptions = ["All", "Mobile", "Web", "Machine Learning", "UI/UX"];

  return (
    <section id="portfolio" className="p-8 font-suse ">
      <div className="flex flex-col items-center justify-center mb-8">
        <h2
          ref={(el) => (cardsRef.current[0] = el)}
          className="flex text-4xl font-extrabold text-white pb-2 mb-4 text-center border-b-2 border-customBlue w-full max-w-[1200px] zoom"
        >
          Digital <b className=" text-customBlue">‎ Portfolio</b>
        </h2>

        {/* Filter Buttons */}
        <div className="flex flex-wrap gap-4 mb-8 justify-center">
          {filterOptions.map((filter) => (
            <button
              key={filter}
              onClick={() => setActiveFilter(filter)}
              className={`px-4 py-2 rounded-full text-sm font-bold ${
                activeFilter === filter
                  ? "bg-customBlue text-black"
                  : "bg-gray-100 text-gray-700"
              } hover:bg-customBlue2 hover:text-white transition duration-300`}
            >
              {filter}
            </button>
          ))}
        </div>
      </div>

      <div className="flex items-center justify-center">
        <div className="flex flex-wrap items-center justify-center gap-20 w-full max-w-[1200px] ">
          {filteredData.map((item, index) => (
            <div
              key={index}
              ref={(el) => (cardsRef.current[index + 1] = el)}
              data-filter={item.category.join(",")} // Add data-filter attribute
              className="cursor-pointer text-center rounded-lg fade"
              onClick={() => openModal(item)}
            >
              <div className="relative min-w-[300px] w-full h-60 max-w-[300px]">
                {!imageLoaded[index] && (
                  <Blurhash
                    hash="L7Fo~h%d7j1621K8-Z$]0vRjEI,]"
                    width="100%"
                    height="100%"
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                  />
                )}

                <img
                  src={item.image}
                  alt={item.title}
                  className={`w-full h-full rounded-lg object-cover transition-transform duration-300 ease-in-out ${
                    imageLoaded[index] ? "block" : "hidden"
                  } hover:transform hover:-translate-y-1`}
                />
              </div>
              <h3 className="text-white font-bold text-xl p-2  w-full max-w-[320px]">
                {item.title}
              </h3>
            </div>
          ))}
        </div>
      </div>

      {selectedItem && (
        <ModalView
          isOpen={modalIsOpen}
          onClose={closeModal}
          selectedItem={selectedItem}
        />
      )}
    </section>
  );
};

export default Portfolio;
