import React from "react";
import "./loadingSpinner.css"; // Import the CSS for the spinner

const LoadingSpinner = () => (
  <div className="loader-container">
    <div className="loader">
      <div className="loader-inner"></div>
    </div>
    <p className="textBot">Hey, you are adorable!</p>
  </div>
);

export default LoadingSpinner;
