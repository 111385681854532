import React, { useRef } from "react";
import { educationData, experienceData } from "../../constant/constant";
import { useIntersectionObserver } from "../../animation/intersectionObserver";

const EducationExperience = () => {
  const cardsRef = useRef([]);
  useIntersectionObserver(cardsRef);
  let itemIndex = 1;

  return (
    <section
      id="education"
      className="flex justify-center p-8 text-white flex-wrap font-suse"
    >
      <div className="flex flex-col lg:flex-row">
        <div id="education" className="flex-1 mr-0 lg:mr-5 w-full lg:max-w-xl ">
          <h2
            ref={(el) => (cardsRef.current[0] = el)}
            className="text-4xl font-extrabold text-white border-b-2 border-customBlue pb-2 mb-4 zoom"
          >
            Education
          </h2>
          {educationData.map((item, index) => (
            <div
              key={index}
              ref={(el) => (cardsRef.current[itemIndex++] = el)}
              className="mb-6 fade group"
            >
              <h3 className="text-2xl font-bold  mb-2 group-hover:text-customBlue">
                {item.title}
              </h3>
              <p className="text-xl font-semibold text-gray-400 mb-2 group-hover:text-white">
                {item.subtitle}
              </p>
              {item.cgpa && (
                <p className="text-md font-semibold text-gray-400 mb-2 group-hover:text-white">
                  {item.cgpa}
                </p>
              )}
              <p className="text-md font-semibold text-gray-400 mb-2 group-hover:text-white">
                {item.year}
              </p>
              <p className="text-lg font-light font-dm-sans text-justify text-gray-400 group-hover:text-white">
                {item.description}
              </p>
            </div>
          ))}
        </div>

        <div id="experience" className="flex-1 ml-0 lg:ml-5 w-full lg:max-w-xl">
          <h2
            ref={(el) => (cardsRef.current[itemIndex++] = el)}
            className="text-4xl font-extrabold text-white border-b-2 border-customBlue pb-2 mb-4 zoom"
          >
            Experience
          </h2>
          {experienceData.map((item, index) => (
            <div
              key={index}
              ref={(el) => (cardsRef.current[itemIndex++] = el)}
              className="mb-6 fade group"
            >
              <h3 className="text-2xl font-bold  mb-2 group-hover:text-customBlue">
                {item.title}
              </h3>
              <p className="text-xl font-semibold text-gray-400 mb-2 group-hover:text-white">
                {item.subtitle}
              </p>
              {item.cgpa && (
                <p className="text-md font-semibold text-gray-400 mb-2 group-hover:text-white">
                  {item.cgpa}
                </p>
              )}
              <p className="text-md font-semibold text-gray-400 mb-2 group-hover:text-white">
                {item.year}
              </p>
              <p className="text-lg font-light font-dm-sans text-justify text-gray-400 group-hover:text-white">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default EducationExperience;
