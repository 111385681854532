import AboutSection from "./aboutSection/index";
import IntroSection from "./IntroSection/index";
import EducationSection from "./educationSection/index";
import SkillSection from "./skillSection/index";
import PortfolioSection from "./portfolioSection/index";
import DesignSection from "./designSection/index";
import AwardSection from "./awardSection/index";
import CertSection from "./certSection/index";
import ActivitySection from "./activitySection/index";
import HobbySection from "./hobbySection/index";
import BlogSection from "./blogSection/index";
import ServiceSection from "./serviceSection/index";
import TestimonialSection from "./testimonialSection/index";
import ContactSection from "./contactSection/index";
import InterestSection from "./interestSection/index";
import ComingSoon from "./comingsoon";
import Fab from "@mui/material/Fab";
import { FaArrowUp } from "react-icons/fa";
import VideoBg from "../assets/videos/intro_bg2.mp4";

const GradientWrapper = ({ children }) => {
  const gradientStyle = {
    background:
      "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(0,41,118,1) 80%, rgba(54,123,252,1) 100%)",
    minHeight: "100vh",
    padding: "20px",
  };

  return <div style={gradientStyle}>{children}</div>;
};

const VideoWrapper = ({ children }) => {
  const videoStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
    background: "#081b29",
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh", padding: "20px" }}>
      <video style={videoStyle} autoPlay loop muted src={VideoBg} />
      <div style={{ position: "relative", zIndex: 1 }}>{children}</div>
    </div>
  );
};

const HomeSection = () => {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <>
      <GradientWrapper>
        <IntroSection />
        <AboutSection />
        <EducationSection />
        <SkillSection />
        <PortfolioSection />
        {/* <DesignSection /> */}
        <AwardSection />
        <CertSection />
        <ActivitySection />
        <HobbySection />
        {/* <BlogSection /> */}
        {/* <ServiceSection />
        <TestimonialSection /> */}
        <ContactSection />
        {/* <InterestSection /> */}
      </GradientWrapper>
      {/* <ComingSoon /> */}
      {/* <Fab
        aria-label="add"
        style={{
          position: "fixed",
          bottom: "50px",
          right: "50px",
          zIndex: 1000,
          backgroundColor: "#00abf0",
        }}
        onClick={() => scrollToSection("header")}
      >
        <FaArrowUp className="text-black text-3xl" />
      </Fab> */}
    </>
  );
};

export default HomeSection;
