import React, { useRef, useState } from "react";
import { awardsData } from "../../constant/constant";
import { useIntersectionObserver } from "../../animation/intersectionObserver";
import "./styles.css"; // Make sure to import the CSS file for styling
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

const Award = () => {
  const cardsRef = useRef([]);
  const [showAll, setShowAll] = useState(false);
  const sectionRef = useRef(null); // Ref for the section

  useIntersectionObserver(cardsRef, showAll);

  const handleToggle = () => {
    setShowAll(!showAll);
    if (showAll) {
      // Use requestAnimationFrame to ensure smooth scrolling on iOS
      requestAnimationFrame(() => {
        // Force a reflow to update layout
        sectionRef.current.style.position = "relative";
        sectionRef.current.style.position = "";
        // Get the position of the element and apply the offset
        const elementPosition = sectionRef.current.offsetTop;
        const offset = -50; // Adjust this value for the offset (e.g., header height)
        const offsetPosition = elementPosition + offset;

        // Scroll to the position with offset
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      });
    }
  };

  const displayedAwards = showAll ? awardsData : awardsData.slice(0, 3);

  return (
    <section id="awards" className="p-8 font-suse" ref={sectionRef}>
      <div className="flex flex-col items-center justify-center">
        <h2
          ref={(el) => (cardsRef.current[0] = el)}
          className="flex text-4xl font-extrabold text-white pb-2 mb-4 text-center border-b-2 border-customBlue w-full max-w-[1200px] zoom"
        >
          Award
        </h2>
        <div className="flex flex-wrap justify-center gap-10 w-full max-w-[1200px] ">
          {displayedAwards.map((award, index) => (
            <div
              ref={(el) => (cardsRef.current[index + 1] = el)}
              className="w-[300px] h-[200px] m-4 perspective-1000 fade"
              key={index}
            >
              <div className="relative w-full h-full transition-transform duration-600 transform-style-3d">
                <div className="absolute w-full h-full flip-card-inner">
                  {/* Front Side */}
                  <div className="flip-card-front rounded-lg shadow-md flex flex-col justify-center items-center overflow-hidden p-4 box-border bg-gray-800">
                    <h3 className="font-bold text-white text-[1.2rem] m-0">
                      {award.title}
                    </h3>
                    <span className="text-[#dedede] text-[1rem] italic absolute top-4 right-4">
                      {award.year}
                    </span>
                    <span className="text-customBlue font-bold text-[1rem] absolute bottom-4 left-4">
                      {award.sub}
                    </span>
                  </div>
                  {/* Back Side */}
                  <div className="flip-card-back flex justify-center items-center bg-gray-900 rounded-lg shadow-md">
                    {/* Content for back side */}
                    <p className="text-white text-lg p-4 text-center">
                      {award.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <button
            onClick={handleToggle}
            className="bg-customBlue text-black font-bold py-2 px-4 rounded-3xl hover:bg-customBlue2 transition-colors duration-300 flex items-center gap-2"
          >
            {showAll ? (
              <FaArrowUp className="fa-beat" />
            ) : (
              <FaArrowDown className="fa-beat" />
            )}
            <span>{showAll ? "See Less" : "See More"}</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Award;
